
<template>
  <div>
  <v-navigation-drawer class="blue nav" mini-variant-width="70" width="250" light :mini-variant.sync="mini" v-model="drawer" app>
      <v-list class="pa-0">
        <v-list-item tag="div">
          <v-list-item-content>
            <v-list-item-title>
              <v-img :src="require('../assets/logo.png')" max-width="5.5em"/>
            </v-list-item-title>
          </v-list-item-content>
          
          <v-list-item-action style="max-width:1em">
            <v-menu bottom right offset-y origin="bottom right" transition="v-slide-y-transition">
              <!-- <template v-slot:activator="{ on }">
                <v-btn icon small light slot="activator" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template> -->
              <!-- <v-list>
                <v-list-item v-for="item in userMenus" :key="item.title" value="true" @click="handleUserActions(item)" router>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list> -->
            </v-menu>
          </v-list-item-action>
          <v-list-item-action style="max-width:1em">
            <v-btn icon small @click.native.stop="mini = !mini">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-list class="pa-0">
        <v-list-item tag="div">
          <!-- <v-list-item-action>
            <v-img max-width="1.5em" class="avatar" :src="require('../assets/avatar.png')"/>
          </v-list-item-action> -->
          <v-list-item-content>
              <v-list-item-title>{{ getUser.name }} {{ getUser.surname }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item
          v-for="(menu, index) in filteredMenus"
          :key="index"
          :exact-active-class="'active-menu'"
          :to="menu.path"
          tag="router-link"
        >
          <v-list-item-action class="pr-1 pl-2 mr-1">
            <v-icon>
              {{ menu.icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="menu.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>  
      
    </v-navigation-drawer>
    <v-app-bar app>
        <v-app-bar-nav-icon @click.native.stop="drawer = !drawer" light></v-app-bar-nav-icon>
    </v-app-bar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'NavigationView',
  data() {
    return {
      menus: [
        // { title: 'Home', icon: 'mdi-home', path:'x' },
        { title: 'ประชุม', icon: 'mdi-book-multiple', path: 'ListMeeting', adminOnly: false},
        { title: 'ตั้งค่า', icon: 'mdi-cog', path: 'ListUser',adminOnly: true },
        { title: 'logout', icon: 'mdi-logout', path: 'logout',adminOnly: false },
        
      ],
      activeMenuItem: '',
      mini: false,
      drawer: window.innerWidth > 960,
    }
  },
  // watch: {
  //   $route(to) {
  //     const matchedItem = this.items.find(item => item.path === to.path);
  //     if (matchedItem) {
  //       this.activeMenuItem = matchedItem.title;
  //     }
  //   }
  // },
  computed: {
    ...mapGetters(['getUser']),
    filteredMenus() {
      return this.menus.filter(menu => {
        return !menu.adminOnly || this.getUser.admin === 1;
      });
    },
  },
  
  methods: {
    handleNavigation(item) {
      this.activeMenuItem = item.title;     

      this.$router.push(item.path).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err; 
        }
      });
    }
  }
}
</script>
<style scoped>
.active-menu {
  background-color: #fff000 !important;
}
.blue.nav{
  background-color: #FFC600 !important;
  border-color: #FFC600 !important;
}
</style>