<template>
  <v-container>
    <v-row class="text-center" v-if="page == 'joinmeet'">
      <v-col cols="12">
        <v-img
          :src="require('../assets/image-depa.jpg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="headline font-weight-bold mb-3">
          ลงทะเบียนเข้าร่วมประชุม
        </h1>

      </v-col>

      
    </v-row>
  </v-container>
  
  
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2';
import { mapActions } from 'vuex';

export default {
  name: 'CallbackThaid',
  data() {
    return { 
      loading: false ,
      page: ''
    };
  },
  async mounted() {
    try {
      
      
      if (this.$route.query.state == 'login'){
        this.loading = true;
        let res = await axios.get('https://dmeetingapi.depa.or.th/loginThaid', {
          params: {
            code: this.$route.query.code
          }
        });
        if (res.data.responsecode === 200) {
          this.page = this.$route.query.state;
          this.handlelogin(res.data.user);
          
          // Swal.fire({
          //   type: "success",
          //   title: "ลงทะเบียนสำเร็จ",
          //   icon: "success",
          //   confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
          // });
        } else if (res.data.responsecode === 500) {
          Swal.fire({
            title: "เข้าสู่ระบบไม่สำเร็จ",
            icon: "warning",
            confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
          });
          this.$router.push('/login'); 
        }
        
       
      }
      else {
        this.loading = true;
        let res = await axios.get('https://dmeetingapi.depa.or.th/memberjoin', {
          params: {
            code: this.$route.query.code,
            state: this.$route.query.state
          }
        });
        if (res.data.responsecode === 200) {          
          Swal.fire({
            type: "success",
            title: "ลงทะเบียนสำเร็จ",
            icon: "success",
            confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
          });
        } else if (res.data.responsecode === 400) {
          Swal.fire({
            title: "ลงทะเบียนไม่สำเร็จ",
            icon: "warning",
            confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
          });
        }
        this.page = 'joinmeet';
        
      }
      
    } catch (e) {
      console.log(e);
    } 
  },
  methods: {
    ...mapActions(['login']),
    async handlelogin(data) {  
      console.log(data);
          
      await this.login({ username: data });
      
      this.$router.push('/listmeeting'); 
    }
  },
};
</script>
<style lang="scss" scoped></style>
