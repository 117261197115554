<template>
      <div class="home">
        <div class="content">
          <img src="@/assets/Logo-depa.png" alt="Logo" class="logo" style="width: 20%;"/>
          <button class="logo-button" @click="LoginToThaID">
            <img src="@/assets/ThaID-logo.png" alt="logothaid" class="logo" style="width: 100%;" />
            <h3 class="text-login">
              LOGIN
            </h3>
          </button>
        </div>
      </div>
      <!-- <v-flex xs12 sm6 md4 lg3>
          <v-btn  small dark class="primary mr-2" href="https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=eWFBN0xZUXZzRjQ4cWFrS2Ezbjk4cDNrTE9xYlZMYVc&redirect_uri=https://dmeeting.depa.or.th/callback/thaid&scope=pid%20name&state=login">Login ThaID</v-btn>

    </v-flex> -->
      <!-- <v-form @submit.prevent="handleSubmit">
        <v-text-field
          v-model="username"
          label="Username"
          required
        ></v-text-field>
        <v-btn type="submit" color="primary">Login</v-btn>
        
      </v-form> -->
      
</template>
  
<!-- <script>
import axios from 'axios';
//   import { mapActions } from 'vuex';
  
  export default {
    name: 'LoginView',
    data() {
      return {
        username: '',
      };
    },
    mounted (){
    let user = localStorage.getItem('user-info');
    if (user){
        this.$router.push('/meeting');      
    }

  },
    methods: {
        async login(){
            const response = await axios.post('http://localhost:3002/login', {
                username: this.username,
            });
            if (response.data.success){
                localStorage.setItem("user-emeeting", JSON.stringify(response.data))
                this.$router.push('/meeting');
            }
            
        }
    //   ...mapActions(['login']), // Map Vuex actions to use in this component
    //   async login() {
    //     try {
    //       const response = await axios.post('http://localhost:3002/login', {
    //         username: this.username,
    //       });
          
  
    //       if (response.data.success) {
    //         this.login(response.data.user); // Commit the user data to Vuex
    //         if (this.$route.path !== '/meeting') {
    //             this.$router.push('/meeting');
    //         }
    //       } else {
    //         alert('Invalid username or password');
    //       }
    //     } catch (error) {
    //       console.error('Login error:', error);
    //       alert('An error occurred during login');
    //     }
    //   },
    },
  };
</script> -->
<script>
import { mapActions } from 'vuex';

export default {
  name: 'LoginView',
  data() {
    return {
      username: '',
    };
  },
  methods: {
    ...mapActions(['login']),
    async handleSubmit() {      
        await this.login({ username: this.username });
        this.$router.push('/listmeeting'); 
    },
    LoginToThaID() {
      window.location.href = 'https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=eWFBN0xZUXZzRjQ4cWFrS2Ezbjk4cDNrTE9xYlZMYVc&redirect_uri=https://dmeeting.depa.or.th/callback/thaid&scope=pid%20name&state=login'; 
    },
  },

};
</script>


<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0C2F53; 
}

.content {
  display: flex;
  flex-direction: column; 
  align-items: center;
}

.logo {
  height: auto;
  margin-bottom: 20px;
}

.logo-button {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 150px;
}
.text-login {
  color: white;
}
</style>