<template>
    <div class="admin-view">
    <navigation-view></navigation-view>
      <v-container fluid>
      <v-flex xs12>
        <v-card class="grey lighten-4 elevation-0">
          <v-card-title class="title">
            สร้างสมาชิก
            <v-spacer></v-spacer>
            <v-btn   elevation="4" fab small dark class="grey mr-2" @click="cancel()">
              <v-icon dark="">mdi-close-circle-outline</v-icon>
            </v-btn>
            <v-btn   elevation="4"  fab small dark class="purple mr-2" @click="save()">
              <v-icon>mdi-content-save-all</v-icon>
            </v-btn>
            <!-- <v-btn   elevation="4" fab small dark class="blue" @click.native="addProduct()">
              <v-icon>mdi-plus</v-icon>
            </v-btn> -->
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="formValid">
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex md6 xs12>
                  <v-text-field
                    v-model="CreateData.Name"
                    name="Name"
                    label="ชื่อ"
                    class="input-group--focused"
                    :rules="[v => !!v || 'กรุณากรอกชื่อ']"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12>
                  <v-text-field
                    v-model="CreateData.Surname"
                    name="Surname"
                    label="นามสกุล"
                    class="input-group--focused"
                    :rules="[v => !!v || 'กรุณากรอกนามสกุล']"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12>
                  <v-text-field
                    v-model="CreateData.IdCard"
                    name="IdCard"
                    label="เลขบัตรประชาชน"
                    class="input-group--focused"
                    @input="validatePhoneNumber"
                    maxlength="13"
                    :rules="[v => !!v || 'กรุณากรอกเลขบัตรประชาชน']"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12>
                  <v-text-field
                    v-model="CreateData.Department"
                    name="Department"
                    label="ฝ่าย"
                    class="input-group--focused"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md12 xs12>
                  <v-checkbox 
                  label="admin"
                  v-model="CreateData.Admin"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
           </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
  
      <!-- <v-layout row justify-center>
        <v-dialog v-model="addProductModal" width="700" persistent>
          <v-card>
            <v-card-title>{{ modalTitle }}</v-card-title>
            <v-card-text>{{ modalText }}</v-card-text>
            <v-card-text>
              <v-container fluid grid-list-md>
                <v-layout row wrap>
                  <v-flex md6 xs12>
                    <v-select
                      required
                      v-bind:items="categories"
                      label="Category"
                      v-model="categoryId"
                      v-on:change="getProductsByCategory"
                    ></v-select>
                  </v-flex>
                  <v-flex md6 xs12>
                    <v-select required v-bind:items="products" label="Product" v-model="productId"></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn class="green--text darken-1" text="text" @click.native="saveProduct">Confirm</v-btn>
  
              <v-btn class="orange--text darken-1" text="text" @click.native="cancelAddProduct">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout> -->
      <!-- <confirm-dialog
        :dialog="dialog"
        :dialogTitle="dialogTitle"
        :dialogText="dialogText"
        @onConfirm="onConfirm"
        @onCancel="onCancel"
      ></confirm-dialog> -->
      <!-- <v-snackbar v-if="loading === false" :right="true" :timeout="2000" :color="mode" v-model="snackbar">
        {{ notice }}
        <v-btn dark text @click.native="closeSnackbar">Close</v-btn>
      </v-snackbar> -->
    </v-container>
    <footer-view></footer-view>
  </div>
</template>
    
    
<script>
import NavigationView from '../../components/Navigation.vue'
import FooterView from '../../components/Footer.vue';
import axios from 'axios';
import Swal from 'sweetalert2';
// import ConfirmDialog from '../../components/ConfirmDialog.vue';
export default {
  name: 'CreateUserView',
  components: {
    // ConfirmDialog,
    NavigationView,
    FooterView
  },
  data() {
    return {
      CreateData: {
        Name: '',
        Surname: '',
        IdCard: '',
        Department: '',
        Admin:''
      },
      formValid: false,
    };
  },
  methods: {
    cancel() { 
        this.$router.push('ListUser');
    },
    async save(){
      if (this.$refs.form.validate()) {
        try {            
          const res = await axios.post('https://dmeetingapi.depa.or.th/createuser', this.CreateData);
          if (res.data.responsecode == 200){
            
            Swal.fire({
              type: "success",
              title: "บันทึกข้อมูลสำเร็จ",
              icon: "success",
              confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
            });
            this.$router.push('/ListUser'); 
          }
          else {
            Swal.fire({
              title: "ลงทะเบียนไม่สำเร็จ",
              icon: "warning",
              confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
            });

          }
        } catch (error) {            
          console.error('Error:', error);
        }
      }
    
    },
  },
  
}
</script>

<style scoped>
.v-navigation-drawer {
  width: 200px;
}

.v-card-title {
  background-color: #4CAF50;
  color: white;
}

.v-card-subtitle {
  margin-bottom: 20px;
}

.v-text-field {
  margin-bottom: 20px;
}
</style>
    