// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    LOGOUT(state) {
      state.user = null;
      // state.token = '';
      localStorage.removeItem('user');
    },
  },
  actions: {
    async login({ commit }, { username }) {      
      try {
        const response = await axios.post('https://dmeetingapi.depa.or.th/login', { username });                
        const { user } = response.data;

        localStorage.setItem('user', JSON.stringify(user));
        commit('SET_USER', user);
        
        console.log(user);
        

      } catch (error) {
        console.error('Login failed:', error);
      }
    },
    logout({ commit }) {
      localStorage.removeItem('user');      
      commit('SET_USER', null);
    }
  },
  getters: {
    getUser: state => state.user,
  },
});
