import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ListMeetingView from '../views/Meetings/ListMeeting.vue'
import CreateMeetingView from '../views/Meetings/CreateMeeting.vue'
import CallbackThaid from '../views/CallbackThaid.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermCondition from '../views/TermCondition.vue'
import CreateUserView from '../views/Users/CreateUser.vue'
import ListUserView from '../views/Users/ListUser.vue'
import Login from '../components/Login.vue';
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    name: 'home',
    component: Login 
  },
  { 
    path: '/login', 
    name: 'Login',
    component: Login 
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: (to, from, next) => {
      store.dispatch('logout').then(() => {
        next('/login'); 
      });
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/listmeeting',
    name: 'Listmeeting',
    component: ListMeetingView,
    meta: { requiresAuth: true }
  },
  {
    path: '/createmeeting',
    name: 'createmeeting',
    component: CreateMeetingView,
    meta: { requiresAuth: true }
  },
  {
    path: "/callback/thaid",
    name: "CallbackThaid",
    component: CallbackThaid,
  },
  {
    path: "/privacypolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/termcondition",
    name: "termCondition",
    component: TermCondition,
  },
  {
    path: "/createuser",
    name: "createuser",
    component: CreateUserView,
    meta: { requiresAuth: true }
  },
  {
    path: "/listuser",
    name: "listuser",
    component: ListUserView,
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = store.state.user;  
  
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login'); 
  } else {
    next();
  }
});

export default router
