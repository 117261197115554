
<template>
    <div class="admin-view">
      <NavigationView />
      <v-container fluid>
      <v-flex>
        <v-card>
          <v-card-title>
            <span class="title">
                Users
            </span>
            <v-spacer></v-spacer>
            <div>
              <!-- <v-btn   elevation="4" class="blue-grey mr-2" fab small dark @click.native.stop="updateSearchPanel">
                  <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn   elevation="4" class="brown lighten-1  mr-2" fab small dark @click.native="reloadData()">
                  <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn   elevation="4" class="teal darken-2  mr-2" fab small dark @click.native="print()">
                  <v-icon>mdi-printer</v-icon>
              </v-btn> -->
              <v-btn   elevation="4" class="deep-orange darken-3" fab small dark @click.native="add">
                  <v-icon>mdi-plus</v-icon>
              </v-btn>
           </div>
           <!-- <table-header-buttons :add="add" :reloadData="reloadData" :updateSearchPanel="updateSearchPanel"></table-header-buttons> -->
      
          </v-card-title>
          <v-data-table
            v-if="!loading"
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :page.sync="pagination.page"
            class="elevation-1"
            >
            <template slot="item.actions" slot-scope="{ item }">
                <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                <v-icon small @click="remove(item)">mdi-delete</v-icon>
            </template>
        </v-data-table>   
        <v-progress-circular
            v-else
            indeterminate
            color="primary"
            class="ma-auto"
        >
        </v-progress-circular>                                                  
        </v-card>
      </v-flex>
      <v-dialog v-model="editdatadialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit User</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex md6 xs12>
                  <v-text-field
                    v-model="EditData.Name"
                    name="Name"
                    label="ชื่อ"
                    class="input-group--focused"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12>
                  <v-text-field
                    v-model="EditData.Surname"
                    name="Surname"
                    label="นามสกุล"
                    class="input-group--focused"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12>
                  <v-text-field
                    v-model="EditData.IdCard"
                    name="IdCard"
                    label="เลขบัตรประชาชน"
                    class="input-group--focused"
                    maxlength="13"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12>
                  <v-text-field
                    v-model="EditData.Department"
                    name="Department"
                    label="ฝ่าย"
                    class="input-group--focused"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md12 xs12>
                  <v-checkbox 
                  label="admin"
                  v-model="EditData.Admin"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
            <!-- <v-text-field label="Name" v-model="form.name"></v-text-field> -->
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="updateItem">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletedatadialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">ยืนยันการลบ</span>
        </v-card-title>
        <v-card-text>
          คุณต้องการที่จะลบข้อมูล User
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteDialog">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteItem()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <!-- <search-panel :rightDrawer="rightDrawer" @cancelSearch="cancelSearch" @searchData="searchOrders">
        <v-layout row>
          <v-flex xs11 offset-xs1>
            <v-text-field name="reference" label="Reference" light v-model="searchFilter.contain.reference"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs11 offset-xs1>
            <v-text-field name="customer" label="Customer" light v-model="searchFilter.contain.customer"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs11 offset-xs1>
            <v-text-field name="minAmount"  type="number"  label="Min Amount" light v-model="searchFilter.greaterThanOrEqual.amount"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs11 offset-xs1>
            <v-text-field name="maxAmount"  type="number"  label="Max Amount" light v-model="searchFilter.lessThanOrEqual.amount"></v-text-field>
          </v-flex>
        </v-layout> 
      </search-panel> -->
      <!-- <confirm-dialog
        :dialog="dialog"
        :dialogTitle="dialogTitle"
        :dialogText="dialogText"
        @onConfirm="onConfirm"
        @onCancel="onCancel"
      ></confirm-dialog>
      <v-snackbar v-if="loading === false" :right="true" :timeout="2000" :color="mode" v-model="snackbar">
        {{ notice }}
        <v-btn dark text @click.native="closeSnackbar">Close</v-btn>
      </v-snackbar> -->
    </v-container>
    <FooterView></FooterView>
    </div>
    
   
     
</template>
    
    
<script>
import axios from 'axios';
import NavigationView from '../../components/Navigation.vue'
import FooterView from '../../components/Footer.vue';
import Swal from 'sweetalert2';
// import Table from '../../components/Table.vue';

//   import TableHeaderButtons from '../../components/TableHeaderButtons.vue'
//   import SearchPanel from '../../components/SearchPanel.vue';
//   import ConfirmDialog from '../../components/ConfirmDialog.vue';
export default {
    name: 'ListUserView',
    components: {
    // Table,
    NavigationView,
    FooterView

    //   ConfirmDialog,
    //   SearchPanel

    },
    data() {
      return {
        headers: [
            { text: 'Name', value: 'name' },
            { text: 'Surname', value: 'surname' },
            { text: 'Department', value: 'department' },
            { text: 'Actions', value: 'actions', sortable: false }
        ],
        items: [],
        loading: true,
        pagination: {
            page: 1,
            rowsPerPage: 10,
        },
        editdatadialog: false,
        deletedatadialog: false,
        EditData: {
          id: null,
          Name: '',
          Surname: '',
          IdCard: '',
          Department: '',
          Admin: ''
        },
        DataToDelete: null
      };
    },
    mounted() {
    this.fetchData(); 
    },
    methods: {
    async fetchData() {
        try {
        const response = await axios.get('https://dmeetingapi.depa.or.th/getuser'); 
        
        this.items = response.data.result; 
        this.loading = false;
        } catch (error) {
        console.error("Error fetching data:", error);
        this.loading = false;
        }
    },
    add() {
        this.$router.push('CreateUser');
    },
    edit(item) {
      this.EditData.id = item.id;
      this.EditData.Name = item.name;
      this.EditData.Surname = item.surname;
      this.EditData.IdCard = item.id_card;
      this.EditData.Department = item.department;
      this.EditData.Admin = item.admin;
      this.editdatadialog = true;         
    },
    closeDialog() {
      this.editdatadialog = false; 
    },
    updateItem() {
      axios.put(`https://dmeetingapi.depa.or.th/edituser/${this.EditData.id}`, this.EditData)
        .then(response => {
          if (response.data.responsecode == 200){
            Swal.fire({
              type: "success",
              title: "แก้ไขข้อมูลสำเร็จ",
              icon: "success",
              confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
            });
          }
          else {
            Swal.fire({
              title: "แก้ไขข้อมูลไม่สำเร็จ",
              icon: "warning",
              confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
            });

          }
          this.closeDialog(); 
          this.fetchData();
        })
        .catch(error => {
          console.error('Error updating item:', error);
        });
    },
    remove(item) {
      this.DataToDelete = item;
      this.deletedatadialog = true; 
    },
    closeDeleteDialog() {
      this.deletedatadialog = false; 
    },
    deleteItem() {
      
      axios.put(`https://dmeetingapi.depa.or.th/deleteuser/${this.DataToDelete.id}`, this.DataToDelete)
        .then(response => {
          if (response.data.responsecode == 200){
            Swal.fire({
              type: "success",
              title: "ลบข้อมูลสำเร็จ",
              icon: "success",
              confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
            });
          }
          else {
            Swal.fire({
              title: "ลบข้อมูลไม่สำเร็จ",
              icon: "warning",
              confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
            });

          }
          this.closeDeleteDialog(); 
          this.fetchData();
        })
        .catch(error => {
          console.error('Error updating item:', error);
        });
    },
  },
}
</script>

<style scoped>
.v-navigation-drawer {
    width: 200px;
}

.v-card-title {
    background-color: #4CAF50;
    color: white;
}

.v-card-subtitle {
    margin-bottom: 20px;
}

.v-text-field {
    margin-bottom: 20px;
}
</style>
    