<template>
  <div class="admin-view">
  <navigation-view></navigation-view>
    <v-container fluid>
    <v-flex xs12>
      <v-card class="grey lighten-4 elevation-0">
        <v-card-title class="title">
          สร้างการประชุม
          <v-spacer></v-spacer>
          <v-btn   elevation="4" fab small dark class="grey mr-2" @click.native="cancel()">
            <v-icon dark="">mdi-close-circle-outline</v-icon>
          </v-btn>
          <v-btn   elevation="4"  fab small dark class="purple mr-2" @click.native="save()">
            <v-icon>mdi-content-save-all</v-icon>
          </v-btn>
          <!-- <v-btn   elevation="4" fab small dark class="blue" @click.native="addProduct()">
            <v-icon>mdi-plus</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formValid">
          <v-container fluid grid-list-md>
            <v-layout row wrap>
              <v-flex md12 xs12>
                <v-text-field
                  name="Title"
                  label="เรื่อง"
                  class="input-group--focused"
                  v-model="meeting.Title"
                  :rules="[v => !!v || 'กรุณากรอกเรื่อง']"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex md6 xs12>
                <v-menu
                  lazy
                  :close-on-content-click="false"
                  transition="v-scale-transition"
                  v-model="StartDate"
                  offset-y
                  full-width
                  :nudge-left="40"
                  max-width="290px"
                
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field lazy v-on="on" label="Start Date" v-model="meeting.StartDate"  prepend-icon="mdi-calendar" readonly :rules="[v => !!v || 'วันเริ่มประชุม']" required></v-text-field>
                  </template>
                  <v-date-picker v-model="meeting.StartDate" color="green lighten-1" scrollable>
                    <v-spacer></v-spacer>
                      <v-btn 
                        text 
                        @click="StartDate = false">
                        OK
                      </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex md6 xs12>
                <v-menu
                  lazy
                  :close-on-content-click="false"
                  transition="v-scale-transition"
                  v-model="StartTime"
                  offset-y
                  full-width
                  :nudge-left="40"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field 
                      lazy 
                      v-on="on" 
                      label="Start Time" 
                      v-model="meeting.StartTime" 
                      prepend-icon="mdi-clock" 
                      readonly
                      :rules="[v => !!v || 'เวลาเริ่มประชุม']" 
                      required
                      >
                    </v-text-field>
                  </template>
                  <v-time-picker v-model="meeting.StartTime" format="24hr" color="green lighten-1" scrollable>
                    <v-spacer></v-spacer>
                      <v-btn 
                        text 
                        @click="StartTime = false">
                        OK
                      </v-btn>
                  </v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex md6 xs12>
                <v-menu
                  lazy
                  :close-on-content-click="false"
                  transition="v-g-transition"
                  v-model="EndDate"
                  offset-y
                  full-width
                  :nudge-left="40"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field lazy v-on="on" label="End Date" v-model="meeting.EndDate"  prepend-icon="mdi-calendar" readonly></v-text-field>
                  </template>
                  <v-date-picker v-model="meeting.EndDate" color="green lighten-1" scrollable> 
                    <v-spacer></v-spacer>
                      <v-btn 
                        text 
                        @click="EndDate = false">
                        OK
                      </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex md6 xs12>
                <v-menu
                  lazy
                  :close-on-content-click="false"
                  transition="v-scale-transition"
                  v-model="EndTime"
                  offset-y
                  full-width
                  :nudge-left="40"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field 
                      lazy 
                      v-on="on" 
                      label="End Time" 
                      v-model="meeting.EndTime" 
                      prepend-icon="mdi-clock" 
                      readonly>
                    </v-text-field>
                  </template>
                  <v-time-picker v-model="meeting.EndTime" format="24hr" color="green lighten-1" scrollable>
                    <v-spacer></v-spacer>
                      <v-btn 
                        text 
                        @click="EndTime = false">
                        OK
                      </v-btn>
                  </v-time-picker>
                </v-menu>
                
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
    <!-- <v-btn color="primary" @click="showQRModal(qrLink)">Generate QR Code</v-btn> -->
    <v-dialog v-model="qrcodemeeting" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">QR Code</v-card-title>
        <v-card-text style="text-align: center;">
          <img
              :src="qrcodeUrl"
              width="200"
              height="200"
              alt="QR Code"
            />
          <v-text-field 
            v-model="qrLink" 
            label="Enter Link" 
            :value="qrLink">
          </v-text-field>
          <v-btn color="primary" @click="saveQrCode">Save QR Code</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeQRModal()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-layout row justify-center>
      <v-dialog v-model="addProductModal" width="700" persistent>
        <v-card>
          <v-card-title>{{ modalTitle }}</v-card-title>
          <v-card-text>{{ modalText }}</v-card-text>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex md6 xs12>
                  <v-select
                    required
                    v-bind:items="categories"
                    label="Category"
                    v-model="categoryId"
                    v-on:change="getProductsByCategory"
                  ></v-select>
                </v-flex>
                <v-flex md6 xs12>
                  <v-select required v-bind:items="products" label="Product" v-model="productId"></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn class="green--text darken-1" text="text" @click.native="saveProduct">Confirm</v-btn>
            <v-btn class="orange--text darken-1" text="text" @click.native="cancelAddProduct">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout> -->

  </v-container>
  <footer-view></footer-view>
</div>
</template>
  
  
  <script>
  import NavigationView from '../../components/Navigation.vue'
  import FooterView from '../../components/Footer.vue';
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import QRCode from 'qrcode';
  import { mapGetters } from 'vuex';

  export default {
    name: 'CreateMeetingView',
    components: {
      NavigationView,
      FooterView,
    },
    
    computed: {
      ...mapGetters(['getUser'])
    },
    data() {
      return {
        StartDate: false,
        EndDate: false,
        StartTime: false,
        EndTime: false,
        meeting: {
          Title: null,
          StartDate: null,
          EndDate: null,
          StartTime: null,
          EndTime: null
        },
        qrcodemeeting: false,
        qrLink: 'https://www.google.com/',
        qrcodeUrl: "",
        formValid: false,
      };
    },
    mounted() {
      if (!this.$store.state.user) {
        this.$router.push('/login');
      }
    },
    methods: {
      saveQrCode() {
        
        const link = document.createElement('a');
        link.href = this.qrcodeUrl;   
        link.download = `QRcode-${this.meeting.Title}.png`;      
        link.click();
      },
      showQRModal(link){
        this.qrcodemeeting = true;
        QRCode.toDataURL(link, (err, url) => {
          if (err) {
            console.error(err);
            return;
          }
          this.qrcodeUrl = url;
        });          
      },
      closeQRModal(){
        this.qrcodemeeting = false;
      },
      async save(){
        if (this.$refs.form.validate()) {
          const dataMeeting = {
            Title: this.meeting.Title,
            StartDate: this.meeting.StartDate,
            EndDate: this.meeting.EndDate,
            StartTime: this.meeting.StartTime,
            EndTime: this.meeting.EndTime,
            UserId: this.getUser.id
          }
          try{
            
            const res = await axios.post('https://dmeetingapi.depa.or.th/createmeeting', dataMeeting);
            if (res.data.responsecode == 200){
              Swal.fire({
                type: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
              });
              let link = 'https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=eWFBN0xZUXZzRjQ4cWFrS2Ezbjk4cDNrTE9xYlZMYVc&redirect_uri=https://dmeeting.depa.or.th/callback/thaid&scope=pid%20name&state=';
              let imgQRLink = link + res.data.id;
              this.qrLink = imgQRLink;
              
              this.showQRModal(imgQRLink);
            }
            else {
              Swal.fire({
                title: "บันทึกข้อมูลไม่สำเร็จ",
                icon: "warning",
                confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
              });
            }

          }catch (error) {            
            console.error('Error:', error);
          }
      }
        
        

      },
      cancel() { 
         this.$router.push('ListMeeting');
      },
    },
    
  }
  </script>
  
  <style scoped>
  .v-navigation-drawer {
    width: 200px;
  }
  
  .v-card-title {
    background-color: #4CAF50;
    color: white;
  }
  
  .v-card-subtitle {
    margin-bottom: 20px;
  }
  
  .v-text-field {
    margin-bottom: 20px;
  }
  </style>
  