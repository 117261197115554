
<template>
  <div class="admin-view">
    <NavigationView />
    <v-container fluid>
    <v-flex>
      <v-card>
        <v-card-title>
          <span class="title"
            >Meeting
          </span>
          <v-spacer></v-spacer>
            <div>
              <v-btn elevation="4" class="deep-orange darken-3" fab small dark @click.native="add">
                  <v-icon>mdi-plus</v-icon>
              </v-btn>
          </div>    
        </v-card-title>
        <v-data-table
            v-if="!loading"
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            :page.sync="pagination.page"
            class="elevation-1"
            >
            <template slot="item.other" slot-scope="{ item }">
              <v-icon small @click="genQR(item)">mdi-qrcode-scan</v-icon>
              <v-icon small @click="listparticipants(item)">mdi-format-list-bulleted</v-icon>
            </template>
            <template slot="item.actions" slot-scope="{ item }">
              <v-icon small @click="edit(item)">mdi-pencil</v-icon>
              <v-icon small @click="remove(item)">mdi-delete</v-icon>
            </template>
        </v-data-table>   
      </v-card>
    </v-flex>
    <v-dialog v-model="editmeetingdialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Meeting</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex md12 xs12>
                  <v-text-field
                    v-model="EditData.Title"
                    name="Title"
                    label="เรื่อง"
                    class="input-group--focused"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md6 xs12>
                  <v-menu
                    lazy
                    :close-on-content-click="false"
                    transition="v-scale-transition"
                    v-model="StartDate"
                    offset-y
                    full-width
                    :nudge-left="40"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field lazy v-on="on" label="Start Date" v-model="EditData.StartDate"  prepend-icon="mdi-calendar" readonly></v-text-field>
                    </template>
                    <v-date-picker v-model="EditData.StartDate" color="green lighten-1" scrollable>
                      <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          @click="StartDate = false">
                          OK
                        </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex md6 xs12>
                  <v-menu
                    lazy
                    :close-on-content-click="false"
                    transition="v-scale-transition"
                    v-model="StartTime"
                    offset-y
                    full-width
                    :nudge-left="40"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field 
                        lazy 
                        v-on="on" 
                        label="Start Time" 
                        v-model="EditData.StartTime" 
                        prepend-icon="mdi-clock" 
                        readonly>
                      </v-text-field>
                    </template>
                    <v-time-picker v-model="EditData.StartTime" format="24hr" color="green lighten-1" scrollable>
                      <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          @click="StartTime = false">
                          OK
                        </v-btn>
                    </v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex md6 xs12>
                  <v-menu
                    lazy
                    :close-on-content-click="false"
                    transition="v-g-transition"
                    v-model="EndDate"
                    offset-y
                    full-width
                    :nudge-left="40"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field lazy v-on="on" label="End Date" v-model="EditData.EndDate"  prepend-icon="mdi-calendar" readonly></v-text-field>
                    </template>
                    <v-date-picker v-model="EditData.EndDate" color="green lighten-1" scrollable> 
                      <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          @click="EndDate = false">
                          OK
                        </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex md6 xs12>
                  <v-menu
                    lazy
                    :close-on-content-click="false"
                    transition="v-scale-transition"
                    v-model="EndTime"
                    offset-y
                    full-width
                    :nudge-left="40"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field 
                        lazy 
                        v-on="on" 
                        label="End Time" 
                        v-model="EditData.EndTime" 
                        prepend-icon="mdi-clock" 
                        readonly>
                      </v-text-field>
                    </template>
                    <v-time-picker v-model="EditData.EndTime" format="24hr" color="green lighten-1" scrollable>
                      <v-spacer></v-spacer>
                        <v-btn 
                          text 
                          @click="EndTime = false">
                          OK
                        </v-btn>
                    </v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="updateItem">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletemeetingdialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">ยืนยันการลบ</span>
        </v-card-title>
        <v-card-text>
          คุณต้องการที่จะลบข้อมูล User
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteDialog">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteItem()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="qrcodemeeting" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">QR Code</v-card-title>
        <v-card-text style="text-align: center;">
          <img
              :src="qrcodeUrl"
              width="200"
              height="200"
              alt="QR Code"
            />
          <v-text-field 
            v-model="qrLink" 
            label="Enter Link" 
            :value="qrLink">
          </v-text-field>
          <v-btn color="primary" @click="saveQrCode(QRid)">Save QR Code</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeQRModal()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="participants" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">ผู้เข้าร่วมประชุม</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="exportToExcel">Export</v-btn>
        </v-card-actions>
        <v-card-text style="text-align: center;">
            <v-data-table
              :headers="headerParticipants"
              :items="itemsParticipants"
            ></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeListModal()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </v-container>
  <FooterView></FooterView>
  </div>
  
 
   
</template>
  
  
<script>
import axios from 'axios';
import NavigationView from '../../components/Navigation.vue'
import FooterView from '../../components/Footer.vue';
import { mapGetters } from 'vuex';
import QRCode from 'qrcode';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

//   import TableHeaderButtons from '../../components/TableHeaderButtons.vue'
//   import SearchPanel from '../../components/SearchPanel.vue';
//   import ConfirmDialog from '../../components/ConfirmDialog.vue';
export default {
  name: 'ListMeetingView',
  components: {
    NavigationView,
    FooterView

  //   ConfirmDialog,
  //   SearchPanel

  },
  data() {
      return {
        headers: [
            { text: 'Title', value: 'title' },
            { text: 'วันที่', value: 'startdate' },
            { text: 'สิ้นสุด', value: 'enddate' },
            { text: '#', value: 'other', sortable: false },
            { text: 'Actions', value: 'actions', sortable: false }
        ],
        headerParticipants: [
          { text: 'ชื่อ', value: 'name' },
          { text: 'เวลา', value: 'timestamp' },
        ],
        items: [],
        itemsParticipants:[],
        loading: true,
        pagination: {
            page: 1,
            rowsPerPage: 10,
        },
        editmeetingdialog: false,
        deletemeetingdialog: false,
        EditData: {
          id: null,
          Title: '',
          StartDate: '',
          StartTime: '',
          EndDate: '',
          EndTime: '',
        },
        StartDate: false,
        EndDate: false,
        StartTime: false,
        EndTime: false,
        DataToDelete: null,
        qrcodemeeting: false,
        participants: false,
        qrLink: '',
        qrcodeUrl: '',
    };
  },
  computed: {
    ...mapGetters(['getUser'])
  },
  mounted() {
    this.fetchData(); 
    // if (!this.$store.state.user) {
    //   this.$router.push('/login');
    // }
  },
  methods: {
    async fetchData() {
        try {  
          const response = await axios.get(`https://dmeetingapi.depa.or.th/getmeeting/${this.getUser.id}`);  
          this.items = response.data.result; 
          this.loading = false;
        } catch (error) {
          console.error("Error fetching data:", error);
          this.loading = false;
        }
    },
    add() {
        this.$router.push('CreateMeeting');
    },
    edit(item) {      
      this.EditData.id = item.id;
      this.EditData.Title = item.title;

      const [startdate, starttime] = item.startdate.split(' ');
      this.EditData.StartDate = startdate;
      this.EditData.StartTime = starttime.slice(0, 5); 

      const [enddate, endtime] = item.enddate.split(' ');
      this.EditData.EndDate = enddate;
      this.EditData.EndTime = endtime.slice(0, 5); 

      this.editmeetingdialog = true;

    },
    closeDialog() {
      this.editmeetingdialog = false; 
    },
    remove(item) {
      this.DataToDelete = item;
      this.deletemeetingdialog = true; 
    },
    closeDeleteDialog() {
      this.deletemeetingdialog = false; 
    },
    genQR(item){        
      this.qrcodemeeting = true;
      let link = 'https://imauth.bora.dopa.go.th/api/v2/oauth2/auth/?response_type=code&client_id=eWFBN0xZUXZzRjQ4cWFrS2Ezbjk4cDNrTE9xYlZMYVc&redirect_uri=https://dmeeting.depa.or.th/callback/thaid&scope=pid%20name&state=';
      let imgQRLink = link + item.id;
      this.qrLink = imgQRLink;
      this.QRid = item.id;
      QRCode.toDataURL(imgQRLink, (err, url) => {
        if (err) {
          console.error(err);
          return;
        }
        
        this.qrcodeUrl = url;
      });          
    },
    async listparticipants(item){  
      this.participants = true;
      try {
        const response = await axios.get(`https://dmeetingapi.depa.or.th/participants/${item.id}`);
        console.log(response.data.result);
        
        this.itemsParticipants = response.data.result; 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      // try {
      //     const response = axios.get('http://localhost:3002/participants/${this.item.id}');   
      //     console.log(response);
          
      //     // this.items = response.data.result; 
      //   } catch (error) {
      //     console.error("Error data:", error);
      //     this.loading = false;
      //   }
        
        // .then(response => {
        //   if (response.data.responsecode == 200){
        //     Swal.fire({
        //       type: "success",
        //       title: "แก้ไขข้อมูลสำเร็จ",
        //       icon: "success",
        //       confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
        //     });
        //   }
        //   else {
        //     Swal.fire({
        //       title: "แก้ไขข้อมูลไม่สำเร็จ",
        //       icon: "warning",
        //       confirmButtonText: '<span style="padding: 15px">ปิด</span>',
        //     });

        //   }
        //   this.closeDialog(); 
        //   this.fetchData();
        // })
        // .catch(error => {
        //   console.error('Error updating item:', error);
        // });
          
    },
    closeQRModal(){
      this.qrcodemeeting = false;
    },
    closeListModal(){
      this.participants = false;
    },
    updateItem() {
      axios.put(`https://dmeetingapi.depa.or.th/editmeeting/${this.EditData.id}`, this.EditData)
        .then(response => {
          if (response.data.responsecode == 200){
            Swal.fire({
              type: "success",
              title: "แก้ไขข้อมูลสำเร็จ",
              icon: "success",
              confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
            });
          }
          else {
            Swal.fire({
              title: "แก้ไขข้อมูลไม่สำเร็จ",
              icon: "warning",
              confirmButtonText: '<span style="padding: 15px">ปิด</span>',
            });

          }
          this.closeDialog(); 
          this.fetchData();
        })
        .catch(error => {
          console.error('Error updating item:', error);
        });
    },
    deleteItem() {
      
      axios.put(`https://dmeetingapi.depa.or.th/deletemeeting/${this.DataToDelete.id}`, this.DataToDelete)
        .then(response => {
          if (response.data.responsecode == 200){
            Swal.fire({
              type: "success",
              title: "ลบข้อมูลสำเร็จ",
              icon: "success",
              confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
            });
          }
          else {
            Swal.fire({
              title: "ลบข้อมูลไม่สำเร็จ",
              icon: "warning",
              confirmButtonText: '<span style="padding: 15px" >ปิด</span>',
            });

          }
          this.closeDeleteDialog(); 
          this.fetchData();
        })
        .catch(error => {
          console.error('Error updating item:', error);
        });
    },

    saveQrCode(item) {
        
        const link = document.createElement('a');
        link.href = this.qrcodeUrl;   
        
        link.download = `QRcode-${item}.png`;      
        link.click();
      },
      exportToExcel() {
      const data = this.itemsParticipants.map(item => ({
        'ชื่อ': item.name,
        'ID_CARD': item.id_card,
        'Time': item.timestamp,
      }));

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      XLSX.writeFile(workbook, 'Export.xlsx');
    }
    
  },
}
</script>

<style scoped>
.v-navigation-drawer {
  width: 200px;
}

.v-card-title {
  background-color: #4CAF50;
  color: white;
}

.v-card-subtitle {
  margin-bottom: 20px;
}

.v-text-field {
  margin-bottom: 20px;
}
</style>
  